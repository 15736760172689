<template>
    <div class="shopDecoration">
        <el-table :data="tableData" border style="width: 100%;" height="100%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F5F5F5'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column label="考试内容" align="center" width="200">
                <template slot-scope="scope">
                    <span>{{scope.row.title}}</span>
                </template>
            </el-table-column>
            <el-table-column label="答案概要" align="center">
                <template slot-scope="scope">
                    <div class="good-cover-wrapper">
                        <div class="good-cover" v-if="Array.isArray(scope.row.answer)">
                            <router-link :to="{path: '/student/store/gooddetail', query: {id: item.id}}" target="_blank" class="item" v-for="item in scope.row.answer">
                                <img :src="item.url" alt="">
                            </router-link>
                        </div>
                        <span v-else-if="scope.row.answer.indexOf('http:')">{{scope.row.answer}}</span>
                        <div v-else class="good-cover">
                            <div v-if="scope.row.title === '店招'" class="dianzhao">
                                <img :src="scope.row.answer" alt="">
                            </div>
                            <div v-if="scope.row.title === '电脑海报'" class="banner">
                                <img :src="scope.row.answer" alt="">
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <router-link :to="{path: '/student/trainmanage/store', query: {xlId: Number($route.query.xlid)}}" target="_blank">
                        <el-button type="text">查看详情</el-button>
                    </router-link>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                trainId: Number(this.$route.query.xlid) || null,
                studentId: Number(this.$route.query.uid) || null,
                competitionId: Number(this.$route.query.cid) || null,
                tableData: [],
            }
        },
        mounted() {
            this.getStudentDetail()
        },
        methods: {
            getStudentDetail() {
                let params = {}
                if (this.trainId) {
                    params.ts_id = this.trainId
                }
                this.$http.axiosGetBy(this.$api.decorate_studentZxTrainAnswer, params, (res) => {
                    if (res.code === 200) {
                        let tmp = {
                            title: '',
                            answer: ''
                        }
                        for (let key in res.data) {
                            if (key === 'banner') {
                                tmp = {
                                    title: '电脑海报',
                                    answer: res.data.banner.url
                                }
                                this.tableData.push(tmp)
                            } else if (key === 'class') {
                                tmp = {
                                    title: '店铺分类',
                                    answer: res.data.class.class_name
                                }
                                this.tableData.push(tmp)
                            } else if (key === 'dianzhao') {
                                tmp = {
                                    title: '店招',
                                    answer: res.data.dianzhao.url
                                }
                                this.tableData.push(tmp)
                            } else if (key === 'goods') {
                                if (Array.isArray(res.data.goods.goodsInfo)) {
                                    let arr = []
                                    for (let i = 0; i < 5 && i < res.data.goods.goodsInfo.length; i++) {
                                        let itemTmp = {
                                            id: res.data.goods.goodsInfo[i].id,
                                            url: res.data.goods.goodsInfo[i].url
                                        }
                                        arr.push(itemTmp)
                                    }
                                    tmp = {
                                        title: '商品主图',
                                        answer: arr,
                                    }
                                } else {
                                    tmp = {
                                        title: '商品主图',
                                        answer: res.data.goods.goodsInfo,
                                    }
                                }
                                this.tableData.push(tmp)
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .look-detail {
        color: #0200D8;
        &:hover {
            color: #5864e4;
        }
    }
    .shopDecoration {
        height: calc(100vh - 275px);
    }
    .good-cover-wrapper {
        text-align: center;
        .good-cover {
            display: flex;
            align-items: center;
            justify-content: center;
            .item {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .item + .item {
                margin-left: 10px;
            }
            .banner {
                /*width: 80%;*/
                width: 182.857142px;
                height: 0;
                /*padding-bottom: 26.25%;*/
                padding-bottom: 60px;
                position: relative;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .dianzhao {
                width: 960px;
                height: 0;
                padding-bottom: 60px;
                position: relative;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .class-item {
            }
        }
    }
</style>