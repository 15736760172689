<template>
    <div class="shopDecoration">
        <el-table :data="tableData" border style="width: 100%;" height="100%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F5F5F5'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" label="名称" align="center"></el-table-column>
            <el-table-column prop="file_name" label="答案" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <a v-if="scope.row.file_path" class="look-detail" :href="scope.row.file_path">下载</a>
                    <a v-else class="look-detail disabled">下载</a>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                trainId: Number(this.$route.query.xlid) || null,
                tableData: [],
            }
        },
        mounted() {
            this.getLyInfo()
        },
        methods: {
            getLyInfo() {
                let params = {}
                if (this.trainId) {
                    params.ts_id = this.trainId
                }
                this.$http.axiosGetBy(this.$api.decorate_studentLyTrainAnswer, params, (res) => {
                    if (res.code === 200) {
                        let tmp = {}
                        if (res.data.length === 0) {
                            tmp = {
                                name: '分析答辩',
                                file_name: '暂无数据',
                                file_path: '',
                            }
                        } else {
                            tmp = {
                                name: '分析答辩',
                                id: res.data[0].id,
                                file_name: res.data[0].file_name,
                                file_path: res.data[0].file_path,
                            }
                        }
                        this.tableData.push(tmp)
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .look-detail {
        line-height: 40px;
        color: #409EFF;
        font-weight: 500;
        display: inline-block;
        cursor: pointer;
        &:hover {
            color: #66b1ff;
        }
        &.disabled {
            color: #aaa;
            cursor: not-allowed;
        }
    }
    .shopDecoration {
        height: calc(100vh - 275px);
    }
</style>